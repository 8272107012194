import React from "react"
import Header from "../components/header/header"
import "../../styles/about.scss"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import "../../styles/bridal-boxes-styles.scss"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const BridalBoxes = ({ data }) => (
  <Layout>
    <Header />
    <SEO
      title="Bridal Boxes | Personally Curated Boxes"
      description="Curated gift boxes that are customized to each occasion. All our products are purchased locally in the Toronto & GTA area. We take great pride in designing and creating custom designed boxes for different parties, events and holidays. Our customers have been able to have a one of a kind experience when it comes to gifting or celebrating special occasions"
    />
    <div className="vendors-wrapper" id="bridal-boxes-wrapper">
      <div className="vendors-text-wrapper">
        <Fade>
          <h2 className="vendor-sub-heading" id="bridal-boxes-sub-heading">
            personally curated
          </h2>
          <h1 className="vendor-heading">Bridal Boxes</h1>
          <p className="vendor-body">
            YESInspired Events not only produces great events, but also designs
            and puts together gift boxes that reflect the style and feeling of a
            specific event or occasion. Whether it is a birthday, wedding,
            congratulations or just because, you are calling in professionals
            like us to make your life a little easier when executing an
            important gift giving moment.
          </p>
          <button className="button" id="bridal-button">
            <Link to="/contact" className="button-link bridal-button-link">
              GET STARTED
            </Link>
          </button>
        </Fade>
      </div>
      <div className="vendor-image" id="bridal-boxes-image">
        <Img
          alt="logo"
          fluid={data.bridalBoxHeaderImage.childImageSharp.fluid}
        />
      </div>
    </div>

    <div>
      <section className="about">
        <Img
          fluid={data.bridalBoxAboutImage.childImageSharp.fluid}
          alt="box with glass cup"
          className="about-img"
        />

        <div className="about-text-wrapper" id="bridal-about-text-wrapper">
          <div>
            <Fade>
              <h1
                className="sub-heading about-sub-heading "
                id="bridal-about-sub-heading"
              >
                take a peek
              </h1>
              <h2 className="heading about-heading">A Look Inside</h2>
            </Fade>
            <Fade>
              <p className="text-body about-body">
                Our collection of curated gift boxes are a fun, easy and
                thoughtful way to give a gift that is meant just for them. Our
                team of experts carefully curated each themed collection to
                ensure the items included complement each other to enhance the
                experience. All our products are handcrafted by local vendors
                and artisans in Toronto, Ontario.
              </p>
            </Fade>
          </div>
        </div>
      </section>
    </div>
    <div className="step-by-step-container">
      <h3 className="bridal-boxes-sub-heading">step-by-step</h3>
      <h2 className="bridal-boxes-heading">How It Works</h2>
      <p className="bridal-boxes-body step-body">
        YESInspired Events not only produces great events, but also designs and
        puts together gift boxes that reflect the style and feeling of a
        specific event or occasion. Get started today with our custom gift boxes
        for every occasion. With just three simple steps we can help you make
        your special day even better.
      </p>

      <div className="step-by-step-wrapper">
        <Fade>
          <div className="step-by-step-component">
            <Img
              className="step-by-step-img"
              fluid={data.bridalBoxImageOne.childImageSharp.fluid}
            />
            <h2 className="bridal-boxes-heading step-by-step-heading">
              Choose Your Box
            </h2>
            <p className="bridal-boxes-body step-by-step-body">
              Start by choosing the perfect gift box package for your event. All
              boxes include, a card, gift box with ribbon & crinkle paper.
            </p>
          </div>
        </Fade>
        <Fade delay={300}>
          <div className="step-by-step-component">
            <Img
              className="step-by-step-img"
              fluid={data.bridalBoxImageTwo.childImageSharp.fluid}
            />
            <h2 className="bridal-boxes-heading step-by-step-heading">
              Customize The Details
            </h2>
            <p className="bridal-boxes-body step-by-step-body">
              Every gift box is customizable with different options and details,
              as well as a customized card to your recipient.
            </p>
          </div>
        </Fade>
        <Fade delay={500}>
          <div className="step-by-step-component">
            <Img
              className="step-by-step-img"
              fluid={data.bridalBoxImageThree.childImageSharp.fluid}
            />
            <h2 className="bridal-boxes-heading step-by-step-heading">
              Set The Delivery
            </h2>
            <p className="bridal-boxes-body step-by-step-body">
              We offer delivery anywhere in Toronto, Brampton, Mississauga and
              Vaughan. Additional fees will apply for delivery outside of these
              regions.
            </p>
          </div>
        </Fade>
      </div>
    </div>
    <div className="gift-collection-container">
      <h2 className="bridal-boxes-heading bridal-boxes-curated-title">
        Curated Gift Collection
      </h2>
      <p className="bridal-boxes-body">
        Explore our curated gift box collection, choose from three different
        packages and we’ll take care of the rest. Your recipient will receive a
        beautifully wrapped box with a personalized card.
      </p>

      {/*BRIDAL BOX COMPONENT*/}
      <div className="service-component" id="bridal-box-component">
        <Img
          className="service-component-image flip-1"
          fluid={data.bridalBoxImageOne.childImageSharp.fluid}
        />
        <div className="service-component-text-wrapper flip-2">
          <Fade>
            <h3 className="service-component-heading">Self-Care Box</h3>
            <h4
              className="service-component-sub-heading"
              id="bridal-box-component-sub-heading"
            >
              Take time to treat yourself
            </h4>
            <div
              className="service-component-line event-component-line"
              id="bridal-component-line"
            >
              &nbsp;
            </div>
            <p className="service-component-body">
              Care for yourself or someone you love with this unique gift box.
              Light a candle, put on a mask, and enjoy some rest and relaxation.
              <br></br>
              <div
                className="event-examples-wrapper event-box-list"
                id="event-box-list"
              >
                <ul className="event-examples">
                  <li>Candle</li>
                  <li>Chocolate</li>
                  <li>Custom pouch</li>
                </ul>
                <ul className="event-examples">
                  <li>Eye mask OR scrunchie</li>
                  <li>Jade roller</li>
                </ul>
              </div>
              <br></br>
              <h5 className="event-pricing">$70+HST</h5>
            </p>

            <Link to="/contact" className="service-component-link">
              <button className="service-component-button event-component ">
                Get this Package
              </button>
            </Link>
          </Fade>
        </div>
      </div>

      {/*BRIDAL BOX COMPONENT*/}
      <div className="service-component" id="bridal-box-component">
        <Img
          className="service-component-image"
          fluid={data.bridalBoxImageTwo.childImageSharp.fluid}
        />
        <div className="service-component-text-wrapper">
          <Fade>
            <h3 className="service-component-heading">Proposal bridal party</h3>
            <h4 className="service-component-sub-heading">
              Say Yes to the box
            </h4>
            <div className="service-component-line event-component-line">
              &nbsp;
            </div>
            <p className="service-component-body">
              Surprise the special bride-to-be with a special personalized and
              customized engagement box, filled with her favorite things.
              <br></br>
              <div className="event-examples-wrapper" id="event-box-list">
                <ul className="event-examples">
                  <li>Scrunchie </li>
                  <li>Candle</li>
                  <li>Custom mug</li>
                </ul>
                <ul className="event-examples">
                  <li>Tea in jars</li>
                  <li>Custom chocolate</li>
                  <li>Habibi day spa certificate</li>
                </ul>
              </div>
              <br></br>
              <h5 className="event-pricing">$85+HST</h5>
            </p>

            <Link to="/contact" className="service-component-link">
              <button className="service-component-button event-component ">
                Get this Package
              </button>
            </Link>
          </Fade>
        </div>
      </div>

      {/*BRIDAL BOX COMPONENT*/}
      <div className="service-component" id="bridal-box-component">
        <Img
          className="service-component-image flip-1"
          fluid={data.bridalBoxImageThree.childImageSharp.fluid}
        />
        <div className="service-component-text-wrapper flip-2">
          <Fade>
            <h3 className="service-component-heading">The Big Day box</h3>
            <h4 className="service-component-sub-heading">
              Celebrate happily ever after
            </h4>
            <div className="service-component-line event-component-line">
              &nbsp;
            </div>
            <p className="service-component-body">
              Enjoy your special day with a unique and personalized gift box to
              mark the occasion.
              <br></br>
              <div className="event-examples-wrapper" id="event-box-list">
                <ul className="event-examples">
                  <li>Wine OR champagne glass</li>
                  <li>Custom Necklace / Earring Set</li>
                  <li>Custom chocolate</li>
                  <li>Dried flowers (product depending on item in stock)</li>
                  <li>Habibi day spa certificate</li>
                </ul>
                <ul className="event-examples"></ul>
              </div>
              <br></br>
              <h5 className="event-pricing">$105+HST</h5>
            </p>

            <Link to="/contact" className="service-component-link">
              <button className="service-component-button event-component ">
                Get this Package
              </button>
            </Link>
          </Fade>
        </div>
      </div>
    </div>
    <div className="bridal-box-image-banner">
      <h3 className="bridal-boxes-sub-heading" id="bridal-vendor-sub-heading">
        brought to you by
      </h3>
      <h2 className="bridal-boxes-heading">Local Vendors</h2>
      <Fade cascade>
        <div className="bridal-boxes-img-wrap">
          <Img
            className="bridal-boxes-vendor-logo"
            fluid={data.bridalVendorImageOne.childImageSharp.fluid}
          />
          <Img
            className="bridal-boxes-vendor-logo"
            fluid={data.bridalVendorImageTwo.childImageSharp.fluid}
          />
          <Img
            className="bridal-boxes-vendor-logo"
            fluid={data.bridalVendorImageThree.childImageSharp.fluid}
          />
          <Img
            className="bridal-boxes-vendor-logo"
            fluid={data.bridalVendorImageFour.childImageSharp.fluid}
          />
          <Img
            className="bridal-boxes-vendor-logo"
            fluid={data.bridalVendorImageFive.childImageSharp.fluid}
          />
        </div>
      </Fade>
    </div>
    <div className="bridal-box-inquiry">
      <Fade>
        <h3 className="bridal-boxes-sub-heading">fully customizable</h3>
        <h2 className="bridal-boxes-heading">One Of a Kind</h2>
      </Fade>
      <Fade>
        <p className="services-page-body" id="bridal-inquiry-body">
          Inquire for more details and information. All our boxes are perfect
          for bridesmaids or bridal party proposals, gifts for special occasions
          and corporate & guest gift boxes. Each box is customized to you and
          your occasion which is why we set up time for a consultation with each
          of our clients to help them put together the perfect box<span>*</span>
          <br></br>
          <br></br>
          We also fully customize boxes from the design to the products. Let us
          know what you are looking for and we will be happy to help.
        </p>
        <div className="asterisk">
          <span>*</span>
          <p className="bridal-inquiry-page-body">
            Items and collections are subject to change seasonally
          </p>
        </div>
      </Fade>
    </div>
  </Layout>
)

export default BridalBoxes

export const vendorQuerry = graphql`
  query {
    bridalBoxHeaderImage: file(
      relativePath: { eq: "bridal-boxes-header.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalBoxAboutImage: file(
      relativePath: { eq: "bridal-boxes-about-image.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalBoxImageOne: file(relativePath: { eq: "bridal-boxes-image-1.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalBoxImageTwo: file(relativePath: { eq: "bridal-boxes-image-2.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalBoxImageThree: file(
      relativePath: { eq: "bridal-boxes-image-3.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalVendorImageOne: file(
      relativePath: { eq: "bridal-boxes-vendor-image-1.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalVendorImageTwo: file(
      relativePath: { eq: "bridal-boxes-vendor-image-2.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalVendorImageThree: file(
      relativePath: { eq: "bridal-boxes-vendor-image-3.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalVendorImageFour: file(
      relativePath: { eq: "bridal-boxes-vendor-image-4.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridalVendorImageFive: file(
      relativePath: { eq: "bridal-boxes-vendor-image-5.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
